<template>
  <div id="splash" >
    <canvas id="renderCanvas"></canvas>
    <div id="overlay-mobile"></div>
    <header id="showcase">
      <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
        Marlon Sijnesael
      </h1>
      <p
        v-bind:class="[isLight ? 'body-title-light' : 'body-title']"
        style="color: #db162f"
      >
        I create web apps and games.
      </p>
      <button
        @click="$vuetify.goTo('#Contour', 5000)"
        class="button"
        v-bind:style="
          isLight
            ? 'color:#202020; border: #202020 1px solid;'
            : 'color:#fff; border: #fff 1px solid;'
        "
      >
        My work
        <v-icon
          v-bind:style="
            isLight
              ? 'color:#202020; '
              : 'color:#fff; '
          "
          >mdi-chevron-down</v-icon
        >
      </button>
    </header>
  </div>
</template>

<script>
//import HelloWorld from '@/components/HelloWorld.vue'
import SceneData from "@/JS/scene.js";
export default {
  name: "Splash",
  components: {},
  mounted() {
    this.sceneData = new SceneData("renderCanvas");
    var canvas_dom = document.getElementById("renderCanvas");
    canvas_dom.addEventListener("touchstart", function (event) {
      event.preventDefault();
    });
    canvas_dom.addEventListener("touchmove", function (event) {
      event.preventDefault();
    });
    canvas_dom.addEventListener("touchend", function (event) {
      event.preventDefault();
    });
    canvas_dom.addEventListener("touchcancel", function (event) {
      event.preventDefault();
    });
  },
  computed: {
    isLight() {
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 4px #202020;
  border-radius: 25px;
  color: #202020;
}

.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #202020;
  color: #202020;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
h1.body-title {
  width: 100vw;
  font-size: 300% !important;
}
#overlay-mobile {
  height: 0%;
  width: 0vw;
  position: fixed;

  left: 0;
  top: 0;
  z-index: -1;
}
#renderCanvas {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  user-select: none;
-webkit-user-select: none;
-moz-user-select: none;
}
#splash {
  height: 80vh;
  width: 100vw;
  margin-top: 48px;
  position: relative;
}

#showcase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #202020;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#showcase h1 {
  font-size: 700% !important;
  line-height: 1.2;
}
.fade-in {
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#showcase p {
  font-size: 20px;
}

#showcase .button {
  font-size: 18px;
  text-decoration: none;
  border: #202020 1px solid;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
}

#showcase .button:hover {
  background: #db162f;
  color: #fff;
}

$size: 45px;

#shapes {
}

.top,
.bottom {
  height: $size;
  width: $size * 2;
}

.right,
.left {
  height: $size * 2;
  width: $size;
  background-color: blue;
}

.top {
  border-top-left-radius: $size * 2;
  border-top-right-radius: $size * 2;
}

.right {
  border-bottom-right-radius: $size * 2;
  border-top-right-radius: $size * 2;
}

.bottom {
  border-bottom-left-radius: $size * 2;
  border-bottom-right-radius: $size * 2;
}

.left {
  border-bottom-left-radius: $size * 2;
  border-top-left-radius: $size * 2;
}

.rotate {
  animation: 1s linear infinite rotate;
  position: relative;
  top: $size * -1;
  transform-origin: 50% 100%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 900px) {
  #overlay-mobile {
    height: 100%;
    width: 100%
    max-width 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
  }
 #showcase h1 {
   width: 100vw;
   font-size: 300% !important;
 }

}
</style>
