import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobile: false,
    viewMode: "light"
  },
  plugins: [createPersistedState({
    paths: ['viewMode']
  })],
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
