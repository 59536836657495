<template>
  <div
    :id="pagedata.name"
    class="page"

    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="other" >
     <div
          @click="$router.push('otherprojects');"
          v-if="$store.state.mobile"
          class="hiddenBtn"

        ></div>
    </div>
    <h1 class="text-about" style="font-size: 600%" v-if="!hover">Other projects</h1>
    <div class="overlay mdAndDown" v-if="hover">
      <div class="row">
        <div class="columnLeft">
          <h1 class="body-title" >Other projects</h1>

          <p class="body-text">
                The projects mentioned above best show who I am as a developer. However, I have done a lot of other school / hobby projects. Here are some of them.
          </p>
          <div class="to-page-btn" v-if="hover">
            <v-btn  block elevation="2" to="otherprojects" outlined dark x-large>
              <span>See my other projects<v-icon>mdi-play</v-icon></span>
            </v-btn>
          </div>
        </div>
        <div class="columnRight">
          <div class="met">
            <!-- <Metadata :ignoreViewMode="true" :showdata="true" :metadata="pagedata.meta"></Metadata> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Metadata from "@/components/Metadata";

export default {
  name: "AbouteMe",
//   components: { Metadata },

  data() {
    return {
      hover: false,
      pagedata: {
        name: "OtherProjects",
        src: "awards.jpg",
        info:
          "Hi! My name is Marlon Sijnesael.<br> I am 24 love you years old and I live in Rotterdam. I am a game/web developer with a passion for new technologies and finding real world applications for them. <br> <br> I studied Creative Media and Game Technologies at the University of the Arts Utrecht (HKU) and I graduated with a Bachelor of Science. During my bachelor I did an internship at the Research and Development departement of the ING Bank. During this internship I was assigned to the Quantum research team, where I worked on serious/applied web and VR games. These games where designed to teach people the basics of quantum computing and to help them gain a better understanding of the core principles of quantum mechanics in general. <br> <br> During this same period I have also worked as a freelance web and game developer.",
        tech: [
          {
            name: "Vue",
            src:
              "https://www.starringjane.com/wp-content/uploads/2018/01/vue-768x768.png",
          },
        ],
        meta: [
          { name: "Birthday", text: "09-05-1996" },
          { name: "City", text: "Rotterdam" },
          { name: "Degree", text: "Creative Media and Game Technologies (BSc)" },
          { name: "Graduated", text: "2019-2020" },
        ],
      },
    };
  },
  computed: {
    showTitle() {
      if (this.$store.state.mobile === true) return false;
      return this.hover;
    },
  },
};
</script>

<style lang="scss">
.other{
    background-color: rgba(0, 0, 0, 0.75); /* give opacity to bg color only */
    height: 50vh;
    width: 100%;
    border-radius: 12px;
}

.pf {
  margin-top: 10px;
  position: relative;
  margin-bottom: 10%;
  border-radius: 4%;
  transform: translate(25%);
}
.met {
  position: relative;
  overflow: hidden;
  font-size: 100%;
  margin-bottom: 25%;
}
.titleText {
  color: white;
  font-size: 500px;
}
.v-image {
  border-radius: 10px;
}

.text-about {
  width: 100%;
  font-size: 1100%;
  font-weight: bolder;
  position: absolute;
  opacity: 1 !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@media only screen and (max-width: 1000px) {
  .hiddenBtn {
    position: relative;
    background: transparent;
    height: 100%;
    width: 100%;
    z-index: 110;
  }
  .other{
      height: 25vh;
  }
}
</style>
