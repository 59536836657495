<template>
  <div>
    <p
      v-bind:class="[!isLight ? 'body-text' : 'body-text-light']"
      v-html="description"
    ></p>
  </div>
</template>

<script>
export default {
  name: "Description",
  components: {},
  props: ["description", "ignoreViewMode"],
  data() {
    return {};
  },
  computed: {
    isLight() {
      if (this.ignoreViewMode == true) return false;
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
  },
};
</script>
