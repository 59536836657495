<template>
  <v-app>
    <div class="cursor"></div>

    <div class="socials">
      <v-btn
        x-large
        text
        class="nav-btn-small"
        v-for="btn in buttons"
        :key="btn.icon"
        @click="redirect(btn.link, btn.target)"
      >
        <v-icon size="32px" color="white" class="nav-btn-icon">{{ btn.icon }}</v-icon>
      </v-btn>
      <v-btn @click="switchMode" x-large text class="nav-btn-small">
        <v-icon
          v-if="this.$store.state.viewMode === 'light'"
          size="32px"
          color="white"
          class="nav-btn-icon"
          >mdi-lightbulb</v-icon
        >
        <v-icon v-else size="32px" color="white" class="nav-btn-icon"
          >mdi-lightbulb-on</v-icon
        >
      </v-btn>
    </div>
    <SplashPage v-if="this.$route.name == 'Home'"></SplashPage>
    <router-view :key="$route.path" />
  </v-app>
</template>

<script>
import SplashPage from "@/components/Splashpage.vue";

export default {
  name: "app",

  components: { SplashPage },
  mounted() {
    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });

    this.$store.state.mobile = this.isMobile();
    const cursor = document.querySelector(".cursor");

    let mouseX = 0;
    let mouseY = 0;

    let cursorX = 0;
    let cursorY = 0;

    let speed = 0.9; // change to increase the ease

    function animate() {
      let distX = mouseX - cursorX;
      let distY = mouseY - cursorY;

      cursorX = cursorX + distX * speed;
      cursorY = cursorY + distY * speed;

      cursor.style.left = cursorX + "px";
      cursor.style.top = cursorY + "px";

      requestAnimationFrame(animate);
    }

    animate();

    document.addEventListener("mousemove", (event) => {
      mouseX = event.pageX;
      mouseY = event.pageY;
    });
  },
  data() {
    return {
      currentMode: "light",
      currentPage: "Contour",
      links: ["Contour", "Milo", "r3d"],
      currentButtons: "socials",
      buttonsSocial: [
        { icon: "mdi-twitter", link: "https://twitter.com/marlonsij", target: "_blank" },
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/lemondrymarlon/?hl=nl",
          target: "_blank",
        },
        {
          icon: "mdi-linkedin",
          link:
            "https://www.linkedin.com/in/marlon-sijnesael-6b9602156/?originalSubdomain=nl",
          target: "_blank",
        },
      ],
      buttonsSkill: [
        { icon: "mdi-arrow-left", link: "/", target: "back" },
        {
          icon: "mdi-github",
          link: "https://github.com/marlonsijnesael",
          target: "_blank",
        },
      ],
    };
  },
  computed: {
    buttons() {
      if (this.$route.name === "Home") {
        return this.buttonsSocial;
      }
      return this.buttonsSkill;
    },
  },
  methods: {
    switchMode() {
      if (this.$store.state.viewMode === "light") {
        this.$store.state.viewMode = "dark";
        localStorage.setItem("mode", "dark");
      } else {
        this.$store.state.viewMode = "light";
        localStorage.setItem("mode", "light");
      }

      let event = new Event("colorChange");
      window.dispatchEvent(event);
    },
    /*eslint no-mixed-spaces-and-tabs: 0*/
    /*eslint  no-useless-escape: 0*/
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    redirect(link, target) {
      // this.currentPage = target;
      // this.$vuetify.goTo("#" + target);

      if (target === "_blank") {
        window.open(link, target);
      } else if (target === "back") {
        this.$router.push({ name: "Home" });
        window.location.reload();
      }
    },
    // scrollToDiv(target, activeEl){

    // }
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/pvy1gzq.css");
$size: 180px;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: none;
  font-family: sofia-pro, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  a {
    text-decoration: none;
    color: #db162f;
  }
}

::-webkit-scrollbar {
  display: none;
}
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #db162f;
}

::selection {
  color: white;
  background: #db162f;
  font-size: 100px;
  border-radius: 50px;
}

.socials {
  position: fixed;
  overflow-x: hidden;
  right: 0;
  top: 0;
  z-index: 10;
  width: 400px;
  height: $size/3;
  background-color: #db162f;
  border-bottom-left-radius: $size * 2;
  animation: widthAnim 0.8s ease-out;
}
@keyframes hide {
  from {
    width: 0px;
    z-index: 1000;
  }
  to {
    width: 400px;
  }
}
@-webkit-keyframes widthAnim {
  from {
    width: 0px;
    z-index: 1000;
  }
  to {
    width: 400px;
  }
}
@keyframes widthAnim {
  from {
    width: 0px;
  }
  to {
    width: 400px;
  }
}
.big-title {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.body-title-light {
  font-size: 30px;
  line-height: 27px;
  word-spacing: 1px;
  font-style: bold;
  margin-bottom: 20px;
  font-weight: 600;
  color: #202020;
}
.body-text-light {
  font-size: 18px;
  line-height: 27px;
  word-spacing: 2px;
  color: #202020;
  font-weight: 400;
}

.body-title {
  font-size: 30px;
  line-height: 27px;
  word-spacing: 1px;
  font-style: bold;
  margin-bottom: 20px;
  font-weight: 600;
  color: #f3f3f3;
}
.body-text {
  font-size: 18px;
  line-height: 27px;
  word-spacing: 2px;
  color: #f3f3f3;
  font-weight: 400;
}

.nav-btn-small {
  color: #202020;
  transition: color 0.2s 0s ease 1s;
}

/* cursor */

.cursor {
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
  mix-blend-mode: difference;
  z-index: 1000;
  pointer-events: none;
}
.text-about {
   opacity: 1 !important;
}
@media only screen and (max-width: 1000px) {
  .pageImg {
    border-radius: 0px;
  }

  .big-title,
  .text-about {
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 300% !important;
  }

  .body-text {
    font-size: 18px;
    line-height: 27px;
    word-spacing: 2px;
    color: #f3f3f3;
  }
  .cursor {
    display: none;
  }
  .socials {
    height: $size/3;
    background-color: #db162f;
  }
}

// .bottom:hover {
//   height: $size;
//   width: $size * 2;
//   background-color: #ee6c4d;
//   border-bottom-left-radius: 25px;
//   border-bottom-right-radius: 0;
//   -webkit-transition:  border-radius 1s;
//   transition:  border-radius 1s;
// }
a {
  text-decoration: none;
  color: #202020;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
h2 {
  color: #202020;
}

.nav-btn {
  width: 100px;
  height: 110%;
  outline: none;
  transition: height 0.5s;
  z-index: 200;
  -webkit-transition: transform 0.5s;
  transition: transform 0.1s;
}

.nav-btn-small {
  height: 50%;
  outline: none;
}
.nav-btn:hover {
  background-color: #ee6c4d;
  transform: scale(1.2);
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  transition: transform 0.1s;
}
.nav-btn-small:hover {
  color: yellow;
}
.nav-btn-small {
  color: white;
}
.nav-btn-active {
  width: 100px;
  height: 99%;
  outline: none;
  background-color: #98c1d9;
  height: 150%;
  border-radius: 0 0 12px 12px;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  transition: height 0.1s;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body #nav {
}
</style>
