<template>
  <div>
    <h1 v-bind:class="[!isLight ? 'body-title' : 'body-title-light']" v-if="showdata">
      Metadata:
    </h1>
    <v-list disabled dark dense class="transparent">
      <v-list-item-group>
        <v-list-item v-for="item in metadata" :key="item.name">
          <v-list-item-content>
            <p
              v-bind:class="[!isLight ? 'body-text' : 'body-text-light', 'no-margin']"
              style="font-weight: bold"
            >
              {{ item.name }}
            </p>
          </v-list-item-content>
          <v-list-item-icon>
            <p
              v-bind:class="[!isLight ? 'body-text' : 'body-text-light', 'no-margin']"
              style="color: #aaa"
              v-html="item.text"
            ></p>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Metadata",
  components: {},
  props: ["metadata", "showdata", "ignoreViewMode"],
  data() {
    return {};
  },
  computed: {
    isLight() {
      if (this.ignoreViewMode == true) return false;
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
  },
};
</script>

<style lang="scss">
.no-margin {
  line-height: 50%;
}
</style>
