const projects = [

    {
        name: "Contour",
        src: "contour.png",
        info: "Contour is the shared graduation project of me and Interaction Designer Jelle Hoogenberg. Contour is a design tool with which you can design and visualize museum exhibitions online. You can easily share the 3D visualisations with stakeholders and they can provide feedback on work completed within the tool. <br/><br/> The goal of this project was to create a vertical slice of a bigger tool and validate our design. We achieved this by working together with the Innvation lab at the Rijksmuseum in Amsterdam. <br/> To make things even better, we even won a HKU Award for Innovation!",
        tech: [
            { name: "Vue", src: "https://www.starringjane.com/wp-content/uploads/2018/01/vue-768x768.png" },
            { name: "Babylonjs", src: "https://www.babylonjs.com/assets/logo-babylonjs-social-twitter.png" },
            { name: "Javasript", src: "https://arsfutura-production.s3.amazonaws.com/jobs/icons/000/000/009/original/91de50e59c.png?1551187120" },
            { name: "NodeJS", src: "https://images.g2crowd.com/uploads/product/image/social_landscape/social_landscape_f0b606abb6d19089febc9faeeba5bc05/nodejs-development-services.png" },
            { name: "Dropbox API", src: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Dropbox_Icon.svg/1101px-Dropbox_Icon.svg.png" },
        ],
        problem: "There are different methods for visualizing exhibition designs. They each have their own qualities as well as drawbacks. Oftentimes, multiple methods are used during a project since there is no single method that encompasses all desired qualities. This creates extra work and can lead to communication problems, especially when working with external partners.",
        recognition: "Contour won the HKU Award 2020 in the category Innovation! It was selected from a list of over 20 projects.<br><br> <q>Contour faciliteert een nieuwe vorm van digitaal ontwerpen, waarmee discipline overstijgende samenwerking maximaal wordt ondersteund.</q> <br> <sub>- HKU Award Jury</sub>",
        routerLink: "contour",
        meta: [{ name: "Period", text: "Jan 2020 - Jul 2020" },
        { name: "Background", text: "Graduation project" },
        { name: "Result", text: "Very succesful" },
        { name: "Responsibilities", text: "Programmer, technical design" },
            { name: "Platform", text: "Chrome / Firefox / Safari" },
            { name: "More details", text: "<a style='color:#db162f; font-weight: 400'  href='https://www.jellehoogenberg.nl/contour'> Jellehoogenberg.nl/contour </a>" }
        ]


    },
    {
        name: "M.I.L.O.",
        src: "3.png",
        info: "M.I.L.O. is a unique 2D dialogue-driven point & click game. However, it’s not made with typical materials! In fact, it’s a combination of mixed media with distinctive visual design and storytelling. The game inspires and intrigues, because of its audiovisual execution. </br></br> Follow a curious fox on its way to discovering the mysteries of an abandoned satellite in space. During this search, you’ll meet four original characters. Of course, all of them have their own immersive stories and personalities.",
        routerLink: "milo",
        tech: [
            { name: "Unity", src: "https://unity3d.com/profiles/unity3d/themes/unity/images/pages/branding_trademarks/unity-tab-square-black.png" },
            { name: "C#", src: "https://pluralsight.imgix.net/paths/path-icons/csharp-e7b8fcd4ce.png" },
            { name: "xNode", src: "https://user-images.githubusercontent.com/37786733/41541140-71602302-731a-11e8-9434-79b3a57292b6.png" },
            { name: "Fmod", src: "https://pbs.twimg.com/profile_images/378800000085420287/9fdea697c121ab2544216d4fbfae8c3f_400x400.png" }
        ],
        recognition: "M.I.L.O. was featured at Dutch Game Garden's Indigo game business showcase and nominated for a HKU award for Artistic achievement!",
        meta: [{ name: "Period", text: "Jan 2020 - Jul 2020" },
        { name: "Background", text: "Passion project" },
        { name: "Result", text: "Validated/ Ongoing" },
            { name: "Robin's page", text: "<a style='color:#db162f; font-weight: 400' href='https://ravenmilo.com/'>www.ravenmilo.com</a>" }
        ]
    }]


export default projects;