<template>
  <div class="home test">

    <div id="pages">
      <AboutMe></AboutMe>
      <div v-for="page in pages" :key="page.name">
        <page :pagedata="page" :ignoreViewMode="true"></page>
      </div>
      <OtherProjects></OtherProjects>

    </div>
  </div>
</template>

<script>
import Page from "@/components/Page.vue";
import AboutMe from "@/components/AboutMe.vue";
import OtherProjects from "@/components/OtherProjects.vue";
import projects from "@/JS/Projects.js";
export default {
  name: "Home",
  components: {
    Page,
    AboutMe,
     OtherProjects
  },
  mounted() {

    this.pages = projects;
  },
  ready(){
            this.$router.go(0);
    },
  data() {
    return {
      pages: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0;
  margin: 0;
}
#pages {
  width: 100vw;
  background-color: #f3f3f3;
  color: white;
}
.bottom{
transition: width 5s 0s ease;

}
.test{
  animation: grow 1s ease ;
}
@keyframes grow {
  0% {
    width: 0px;
  }
  50% {
    width: calc(100vw - 24px);
    height: 2px;
  }
  100% {
    width: calc(100vw - 24px);
    height: calc(100vh - 24px);
  }
}
</style>
