<template>
  <keep-alive>
    <div
      :id="pagedata.name"
      class="page"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <v-img class="pageImg" eager :src="pagedata.src">
        <div
          @click="changePage"
          v-if="$store.state.mobile"
          class="hiddenBtn"
          :src="pagedata.src"
        ></div>
      </v-img>

      <h1 class="text big-title" style="width: 100%" v-if="!showTitle">{{ pagedata.name }}</h1>
      <div class="overlay mdAndDown" v-if="hover">
        <div class="row">
          <div class="columnLeft">
            <h1 class="body-title">What is {{ pagedata.name }}?</h1>

            <Description
              :description="pagedata.info"
              :ignoreViewMode="true"
            ></Description>
            <div class="to-page-btn" v-if="hover">
              <v-btn block elevation="2" @click="changePage" outlined dark x-large>
                <span>See the full project <v-icon>mdi-play</v-icon></span>
              </v-btn>
            </div>
          </div>
          <div class="columnRight">
            <div class="section">
              <Metadata
                :showdata="true"
                :metadata="pagedata.meta"
                :ignoreViewMode="true"
              ></Metadata>
            </div>
            <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div>
          </div>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import Metadata from "@/components/Metadata";
import Technologies from "@/components/Technologies";
import Description from "@/components/Description";

export default {
  name: "Page",
  components: { Metadata, Technologies, Description },
  props: ["pagedata"],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    showTitle() {
      if (this.$store.state.mobile === true) return false;
      return this.hover;
    },
  },
  methods: {
    changePage() {
      this.$router.push(this.pagedata.routerLink);
    },
  },
};
</script>

<style lang="scss">
.section {
  position: relative;
  overflow: hidden;
  font-size: 100%;
}
.section:nth-child(2) {
  margin-top: 12.5%;
}
.titleText {
  color: white;
  font-size: 500px;
}
.to-page-btn {
  margin-top: 25px;
}
.home {
  padding: 0;
  margin: 0;
}
.columnLeft {
  position: relative;
  float: left;
  width: 50%;
  height: 50%;
  padding: 50px;
  text-align: left;
}
.columnRight {
  position: relative;
  float: Right;
  width: 50%;
  height: 50%;
  padding: 50px;
  text-align: left;
}

.row {
  padding-top: 10px;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
h1 {
  font-size: 175%;
}
.page {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 250px;
  width: 68%;
  max-width: 1400px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.page .overlay:hover {
  // opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.75); /* give opacity to bg color only */
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
}
.pageImg {
  max-width: 100%;
  max-height: 100%;
  -webkit-box-shadow: 5px 5px 36px 5px rgba(0, 0, 0, 0.42);
  box-shadow: 5px 5px 36px 5px rgba(0, 0, 0, 0.42);
}

.v-image {
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0); /* give opacity to bg color only */
  border-radius: 10px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
}
h1 *{
  opacity: 1 !important;
}
.text {
  font-size: 1200%;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media only screen and (max-width: 1000px) {
  .hiddenBtn {
    position: relative;
    background: transparent;
    height: 100%;
    width: 100vw;
  }
  .pageImg {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .overlay,
  .to-page-btn {
    display: none;
  }

  .page {
    margin-bottom: 10px;
    padding: 10px;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
  }
  .columnLeft, .columnRight {
    width: 100%;
    padding: 24px;
  }
  .columnRight {
    float: left;
    width: 100%;
  }
}
</style>
