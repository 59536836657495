<template>
  <div >
    <h1 v-if="showHeader" class="body-title">Technologies used:</h1>
    <v-row >
      <v-col  v-for="n in technologies" :key="n.name" class="d-flex child-flex" cols="2">
        <v-img  :alt="n.name" eager :src="n.src" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Technologies",
  components: {},
  props: ["technologies", "showHeader"],
  data() {
    return {};
  },
};
</script>
